import { AUTH_TOKEN } from 'constants/auth';
import fetch from 'cross-fetch';
import { getToken, setToken } from 'utils/auth';

type TrendFetchRequest = (defaultAuthToken?: string) => Promise<Response>;

export const trendFetch = (endpoint: string, options?: RequestInit) => {
  const fetchRequest: TrendFetchRequest = (defaultAuthToken?: string) =>
    fetch(`${process.env.NEXT_PUBLIC_TREND_API}${endpoint}`, {
      ...options,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${
          defaultAuthToken || getToken(AUTH_TOKEN.ACCESS)
        }`,
        ...(options?.headers || {}),
      },
    });

  return fetchRequest().then((response) => {
    if (response.status === 401) {
      return refreshAccessToken(fetchRequest, response);
    }
    return response;
  });
};

let refreshPromise: Promise<any> | null = null;
export const refreshAccessToken = async (
  initialRequest: TrendFetchRequest,
  initialResponse: Response
) => {
  try {
    if (!refreshPromise) {
      refreshPromise = fetch(
        `https://securetoken.googleapis.com/v1/token?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`,
        {
          method: 'POST',
          body: JSON.stringify({
            grant_type: 'refresh_token',
            refresh_token: getToken(AUTH_TOKEN.REFRESH),
          }),
          headers: { 'Content-type': 'application/x-ww-form-urlencoded' },
        }
      ).then((res) => res.json());
    }
    // eslint-disable-next-line camelcase
    const { id_token, refresh_token } = await refreshPromise;
    setToken(AUTH_TOKEN.ACCESS, id_token);
    setToken(AUTH_TOKEN.REFRESH, refresh_token);
    refreshPromise = null;
    return initialRequest(id_token);
  } catch (error) {
    console.error('Could not refresh token');
    console.error(error);
    refreshPromise = null;
    return initialResponse;
  }
};

export const trendSWRFetch = (key: string) =>
  trendFetch(key).then((r) => r.json());
