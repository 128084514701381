import { Creator } from './creator';
import { User } from './user';

export type ErrorResponse = {
  statusCode: number;
  message: string;
};

export enum API_RESPONSE_STATUS {
  OK = 'ok',
  ERROR = 'error',
}

export type AuthResponse = {
  status: API_RESPONSE_STATUS;
  message: string;
};
export type OAuthPlatform = 'google' | 'facebook';

export interface FirebaseAuthSignUpResponse {
  idToken: string;
  email: string;
  refreshToken: string;
  expiresIn: string; // Seconds until idToken expires
  localId: string; // Firebase UID
}

export interface AuthProfile extends FirebaseAuthSignUpResponse {
  user: User;
  creator?: Creator;
}

// export interface FirebaseOAuthSignInResponse
//   extends FirebaseAuthSignUpResponse {
//   needConfirmation: boolean; // TODO: Will need to handle linking accounts? OR is this handle by the Firebase setting
//   federatedId: string;
//   providerId: string;
//   emailVerified: boolean;
//   oauthIdToken: string;
//   oauthAccessToken: string;
//   oauthTokenSecret: string;
// }
