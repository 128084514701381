import { AUTH_TOKEN, TOKEN_EXPIRATIONS } from 'constants/auth';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

export const tokenExists = (tokenKey: AUTH_TOKEN, ctx: any = null) => {
  const cookies = parseCookies(ctx);
  const authToken = cookies[tokenKey];
  return typeof authToken === 'string';
};

export const getToken = (tokenKey: AUTH_TOKEN, ctx: any = null) => {
  const cookies = parseCookies(ctx);
  const authToken = cookies[tokenKey];
  return authToken;
};

export const setToken = (
  tokenKey: AUTH_TOKEN,
  tokenValue: string,
  ctx: any = null
) => {
  setCookie(ctx, tokenKey, tokenValue, {
    maxAge: TOKEN_EXPIRATIONS[tokenKey],
    path: '/',
  });
};

export const destroyToken = (tokenKey: AUTH_TOKEN, ctx?: any) => {
  destroyCookie(ctx, tokenKey);
};

export const standardizeErrorMessage = (errorMessage: string): string => {
  if (errorMessage.includes('There is already an existing user')) {
    return 'There is already a user with that email';
  }

  return typeof errorMessage === 'string'
    ? errorMessage
    : 'Issue authenticating';
};
