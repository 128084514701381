import { setProfileProperty, identify } from 'services/analytics';
import Bugsnag from 'services/bugsnag';
import { User } from 'types/user';

export const setUserIdentity = (user: User) => {
  try {
    const userId = user._id;
    const firebaseUserId = user.firebaseUserId;
    const { email, emailVerified, firstName, lastName } = user;
    const name = `${firstName} ${lastName}`;
    identify(firebaseUserId);
    setProfileProperty('name', name);
    setProfileProperty('email', email);
    setProfileProperty('emailVerified', emailVerified);
    Bugsnag.setUser(userId, email, name);
  } catch (error) {
    console.error('Failed to set user identity');
    console.error(error);
  }
};
