import { trendFetch } from 'api';
import { trackEvent } from 'services/analytics';
import Bugsnag, { addErrorMetaData } from 'services/bugsnag';
import { AuthProfile, AuthResponse, API_RESPONSE_STATUS } from 'types/auth';
import { standardizeErrorMessage } from 'utils/auth';

export interface AuthAPIResponse extends AuthResponse {
  authProfile?: AuthProfile;
}

export const getUserProfile = () => {
  return trendFetch('/auth/profile');
};

export const postAuthSignup = async (
  email: string,
  password: string
): Promise<AuthAPIResponse> => {
  return await trendFetch('/auth/signup', {
    method: 'POST',
    body: JSON.stringify({ email, password }),
  })
    .then(async (res) => {
      const jsonResponse = await res.json();
      const { idToken } = jsonResponse;
      if (res.ok && idToken) {
        return {
          status: API_RESPONSE_STATUS.OK,
          message: 'success',
          authProfile: jsonResponse,
        };
      } else {
        if (res.status === 409) {
          return {
            status: API_RESPONSE_STATUS.ERROR,
            message: 'Email already registered, please login',
          };
        }
        const { message } = jsonResponse;
        const errorMessage = standardizeErrorMessage(
          message || 'Failed to sign up'
        );
        return { status: API_RESPONSE_STATUS.ERROR, message: errorMessage };
      }
    })
    .catch((err) => {
      Bugsnag.notify(
        'postAuthSignup Error',
        addErrorMetaData('error', { error: err })
      );
      return { status: API_RESPONSE_STATUS.ERROR, message: 'Failed To Signup' };
    });
};

export const postLoginUser = (
  email: string,
  password: string
): Promise<AuthAPIResponse> => {
  return trendFetch('/auth/login', {
    method: 'POST',
    body: JSON.stringify({ email, password }),
  })
    .then(async (res) => {
      const jsonResponse = await res.json();
      const { idToken } = jsonResponse;
      if (res.ok && idToken) {
        trackEvent('Creator Logs In');
        return {
          status: API_RESPONSE_STATUS.OK,
          message: 'success',
          authProfile: jsonResponse,
        };
      } else {
        const { message } = jsonResponse;
        const errorMessage = standardizeErrorMessage(
          message || 'Failed to login'
        );
        return { status: API_RESPONSE_STATUS.ERROR, message: errorMessage };
      }
    })
    .catch((err) => {
      Bugsnag.notify(
        'postLoginUser Error',
        addErrorMetaData('error', { error: err })
      );
      return { status: API_RESPONSE_STATUS.ERROR, message: 'Failed to login' };
    });
};

export const postGoogleLogin = (): Promise<AuthAPIResponse> => {
  return trendFetch('/auth/login/google', {
    method: 'POST',
  })
    .then(async (res) => {
      const jsonResponse = await res.json();
      if (res.ok) {
        trackEvent('Creator Logs In with Google');
        return {
          status: API_RESPONSE_STATUS.OK,
          message: 'success',
          authProfile: jsonResponse,
        };
      } else {
        const { message } = jsonResponse;
        const errorMessage = standardizeErrorMessage(
          message || 'Failed to verify google authentication'
        );
        return { status: API_RESPONSE_STATUS.ERROR, message: errorMessage };
      }
    })
    .catch((err) => {
      Bugsnag.notify(
        'postGoogleLogin Error',
        addErrorMetaData('error', { error: err })
      );
      return { status: API_RESPONSE_STATUS.ERROR, message: 'Failed to login' };
    });
};

export const postLogout = () => {
  return trendFetch('/auth/logout', {
    method: 'POST',
  });
};

export const patchUserEmail = async (userId: string, newEmail: string) => {
  // Send request to patch email
  return trendFetch(`/auth/update/email`, {
    method: 'PATCH',
    body: JSON.stringify({ email: newEmail }),
  });
};

export const postVerifyEmail = async (
  code: string
): Promise<{ ok: boolean }> => {
  return fetch(`${process.env.NEXT_PUBLIC_SENGRID_EMAIL_URL}/verify`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({ code }),
  })
    .then((res) => {
      return { ok: res.ok };
    })
    .catch((res) => {
      Bugsnag.notify(
        'postVerifyEmail Failure',
        addErrorMetaData('request info', { code, res })
      );
      return { ok: false };
    });
};
