import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import {
  AuthAPIResponse,
  getUserProfile,
  patchUserEmail,
  postAuthSignup,
  postGoogleLogin,
  postLoginUser,
  postLogout,
} from 'api/auth';
import { AuthProfile, AuthResponse, API_RESPONSE_STATUS } from 'types/auth';
import { User } from 'types/user';
import { setProfileProperty, trackEvent } from 'services/analytics';
import Bugsnag, { addErrorMetaData } from 'services/bugsnag';
import { setUserIdentity } from 'utils/analytics';
import { signInWithGooglePopup } from 'services/firebaseAuth';
import { destroyToken, setToken, tokenExists } from 'utils/auth';
import { AUTH_TOKEN } from 'constants/auth';

interface AuthenticationContext {
  authProfile?: AuthProfile;
  isAuthenticated: boolean;
  isLoading: boolean;
  logout: () => Promise<void>;
  login: (email: string, password: string) => Promise<AuthResponse>;
  googleLogin: () => Promise<AuthResponse>;
  signup: (email: string, password: string) => Promise<AuthResponse>;
  refreshProfile: () => Promise<void>;
  updateUserEmail: (newEmail: string) => Promise<void>;
}

export const AuthContext = createContext<AuthenticationContext>(
  {} as AuthenticationContext
);

export const useAuth = (): AuthenticationContext => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const [authProfile, setAuthProfile] = useState<AuthProfile>();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (!router.isReady) return;
    if (tokenExists(AUTH_TOKEN.ACCESS) || tokenExists(AUTH_TOKEN.REFRESH)) {
      refreshProfile(true);
    } else {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, [router.isReady]);

  const logout = async (clearLocalOnly = false) => {
    try {
      if (!clearLocalOnly && isAuthenticated) {
        trackEvent('Creator Logs Out');
        await postLogout();
      }
    } catch (error: any) {
      Bugsnag.notify('postLogout error', addErrorMetaData('error', { error }));
    }
    destroyToken(AUTH_TOKEN.ACCESS);
    destroyToken(AUTH_TOKEN.REFRESH);
    setAuthProfile(undefined);
    setIsAuthenticated(false);
    setIsLoading(false);
    if (window.localStorage) {
      window.localStorage.clear();
    }
    return;
  };

  const googleLogin = async (): Promise<AuthResponse> => {
    setIsLoading(true);
    return signInWithGooglePopup().then(({ credential, error }) => {
      if (credential) {
        setToken(AUTH_TOKEN.ACCESS, credential.idToken);
        setToken(AUTH_TOKEN.REFRESH, credential.refreshToken);
        return postGoogleLogin().then((res) =>
          handleAuthAPIResponse(res, 'google')
        );
      }
      return handleAuthAPIResponse(error!);
    });
  };

  const login = async (
    email: string,
    password: string
  ): Promise<AuthResponse> => {
    setIsLoading(true);
    return postLoginUser(email, password).then(handleAuthAPIResponse);
  };

  const signup = async (
    email: string,
    password: string
  ): Promise<AuthResponse> => {
    setIsLoading(true);
    return postAuthSignup(email, password).then(handleAuthAPIResponse);
  };

  const handleAuthAPIResponse = (authResponse: AuthAPIResponse, oauth = '') => {
    const { status, message } = authResponse;
    if (status === API_RESPONSE_STATUS.ERROR) {
      logout(true);
    } else {
      const profile = authResponse.authProfile!;
      if (oauth === '') {
        setToken(AUTH_TOKEN.ACCESS, profile.idToken);
        setToken(AUTH_TOKEN.REFRESH, profile.refreshToken);
      }
      setIsAuthenticated(true);
      setUserIdentity(profile.user);
      setAuthProfile(profile);
      setIsLoading(false);
    }
    return { status, message };
  };

  const refreshProfile = async (refresh = isAuthenticated) => {
    if (refresh) {
      setIsLoading(true);
      getUserProfile()
        .then(async (res) => {
          if (res.ok) {
            const freshAuthProfile: AuthProfile = await res.json();
            setAuthProfile(freshAuthProfile);
            setIsAuthenticated(true);
            setIsLoading(false);
            // Analytics & Error Handling
            setUserIdentity(freshAuthProfile.user);
          } else {
            logout(true);
          }
        })
        .catch((error) => {
          logout(true);
          Bugsnag.notify(
            'refreshProfile Error',
            addErrorMetaData('error', { error })
          );
        });
    }
  };

  const updateUserEmail = async (newEmail: string) => {
    if (isAuthenticated && authProfile) {
      setIsLoading(true);
      patchUserEmail(authProfile.user._id, newEmail)
        .then(async (res) => {
          if (res.ok) {
            const updatedUser = (await res.json()) as User;
            setAuthProfile({ ...authProfile, user: updatedUser });
            setProfileProperty('email', updatedUser.email);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          Bugsnag.notify(
            'refreshProfile Error',
            addErrorMetaData('error', { error })
          );
        });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authProfile,
        isAuthenticated,
        logout,
        login,
        googleLogin,
        signup,
        refreshProfile,
        updateUserEmail,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
