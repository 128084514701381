// useWindowSize
// https://usehooks.com/useWindowSize/
import { useState, useEffect } from "react";

// Define general type for useWindowSize hook, which includes width and height
interface Size {
  width: number;
  height: number;
}

// type DeviceWidth = "xs" | "sm" | "md" | "lg" | "xl";
export enum BREAKPOINTS {
  XS = 0,
  SM = 600,
  MD = 900,
  LG = 1200,
  XL = 1536,
}

const useWindowSize = (): Size => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      // Set window width/height to state
      setWindowSize({ width, height });
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export default useWindowSize;
